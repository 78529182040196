import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Container,
  Title,
  Content,
  Button,
  Grid,
  List,
  A
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'

export default ({ data }) => {
  //console.log(data)
  return (
  <Layout>
    <SEO
      title="Mehr Erfolg für Ihr Online-Projekt! - Projectival - Sascha Klapetz"
      description="► 14+ Jahre Online-Marketing & Webentwicklung in Köln / Bonn: ✓ Website-Konzept ✓ UX/SEO-Audit ✓ Landingpage ✓ Leadgenerierung ► Leistungen ansehen"
    />
    <Container>
      <Container.Inner>
        <Content></Content>
        <Title as="h1">Gemeinsam machen wir Ihr nächstes Online-Projekt erfolgreicher</Title>
        <Content big>
          <p>Ich unterstütze Sie bei Konzept und Realisierung Ihrer Online-Marketing Kampagnen oder Webseiten, mit Erfahrung aus über 100 Online-Projekten in den letzten 14 Jahren.</p>
          <Button as={Link} primary="true" to="/leistungen/">Leistungen ansehen</Button>
        </Content>
      </Container.Inner>
    </Container>
    <Container>
      <Container.Inner>
        <Grid className="nb5">
          <Grid.Col className="mb5 w-50-l">
            <Title smaller>Online-Marketing & Webentwicklung aus Köln / Bonn</Title>
            <Content>
              <p>Mit einem Blick sowohl für Marketing „und“ Technik wird Ihr Online-Projekt konzipiert und/oder umgesetzt. Also keine „Silodenke“, sondern alles, damit Sie Ihre Ziele erreichen.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-50-l">
            <List>
              {/*<List.Item><Title smaller><A colored="true" as={Link} to="/ux-seo-audit/">UX/SEO-Audit</A></Title></List.Item>*/}
              <List.Item><Title smaller><A colored="true" as={Link} to="/landingpage/">Landingpage</A></Title></List.Item>
              <List.Item><Title smaller><A colored="true" as={Link} to="/website-konzept/">Website-Konzept</A></Title></List.Item>
              <List.Item><Title smaller><A colored="true" as={Link} to="/leadgenerierung/">Leadgenerierung</A></Title></List.Item>
              <List.Item><Title smaller><A colored="true" as={Link} to="/pagespeed-optimierung/">Pagespeed-Optimierung</A></Title></List.Item>
              {/*<List.Item><Title smaller><A colored="true" as={Link} to="/webentwicklung/">Webentwicklung</A></Title></List.Item>*/}
            </List>
          </Grid.Col>
        </Grid>
      </Container.Inner>
    </Container>
    <Container>
      <Container.Inner>
        <Quote {...data.allTestimonialsYaml.edges.find(el => el.node.name === 'anwaltverlag').node} reverse />
      </Container.Inner>
    </Container>
    <Container>
      <Container.Inner>
        <Grid className="nb5">
          <Grid.Col className="mb5 w-third-l">
            <Title smaller>Erfolg des Projektes ist entscheidend</Title>
            <Content>
              <p>Mir ist wichtig, dass Sie mit Ihrem Online-Projekt erfolgreich sind. Daher biete ich Ihnen mehr als „Malen nach Zahlen“. Bei mir erhalten Sie immer den „Blick über den Tellerrand hinaus“.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Title smaller>Über 14 Jahre Erfahrung</Title>
            <Content>
              <p>In den letzten 14 Jahren als Geschäftsführer und Freelancer habe ich viele tolle Projekte konzipiert und umgesetzt. Und die Kunden dahinter ein Stück weit erfolgreicher gemacht.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Title smaller>Allrounder mit Fachkenntnis</Title>
            <Content>
              <p>Durch meine (recht seltene) Fachkenntnis in „beiden“ Bereichen, Online-Marketing und Webentwicklung, kann ich gut Zusammenhänge erkennen. Mit besseren Resultaten.</p>
            </Content>
          </Grid.Col>
        </Grid>
      </Container.Inner>
    </Container>
    <Contact>
      <Title center>Sie wollen mehr Umsatz, ich helfe Ihnen dabei</Title>
      <Content big center>
        <p>Nehmen Sie jetzt Kontakt zu mir auf, und schildern Sie mir, wo der Schuh drückt. Gemeinsam schauen wir, ob und wie ich Sie unterstützen kann.</p>
        <Button as={Link} primary="true" to="/kontakt/">Kontakt aufnehmen</Button><br />
        <Button as={Link} to="/leistungen/">Leistungen ansehen</Button>
      </Content>
    </Contact>
  </Layout>
)}

export const query = graphql`
  query {
    allTestimonialsYaml(filter: {name: {in: [ "anwaltverlag"]}}) {
      edges {
        node {
          name
          children: content
          source
          image {
            ...rectangleImage
          }
          logo {
            ...logo
          }
        }
      }
    }
  }
`
